export const PRIVATE_CLIENTS_TESTIMONIALS = [
	{
		text: 'Adrian and his team handheld our case efficiently, professionally and everything went well from start to finish. I would not hesitate to recommend Pandev Law.',
		name: 'Robert Astraikis',
		stars: 5,
	},
	{
		text: 'I had found them on Google and turns out they truly deserved all the 5 stars they were given. Not only they are attentive and diligent but also very knowledgeable.\r',
		name: 'Efecan Atilgan',
		stars: 5,
	},
	{
		text: 'Thank you so much Adrian, to you and your team for helping me with my case. It showed that your firm genuinely cares about clients and their cases.\n',
		name: 'Vladyslav Kalaida',
		stars: 5,
	},
	{
		text: 'I could not recommend enough the work of Adrian and his team.\r\nThey have been very professional, helpful, and patient with us from day 1 and brought clarity and calm to a stressful process.\r\nThank you again',
		name: 'Vincent Denis',
		stars: 5,
	},
	{
		text: "It is with great pleasure that I recommend Pandev Law to anyone who is seeking service that provides positive results. My experience has been incredible and I couldn't have made a better choice. Thank you so much for everything!!!!!",
		name: 'Kashan Allen',
		stars: 5,
	},
	{
		text: 'Adrian Pandev is the ultimate professional. He is by far one of the smartest and most meticulous people that I have ever met. I wholeheartedly recommend him and the entire team at Pandev Law. ',
		name: 'Anvon Ferguson',
		stars: 5,
	},
	{
		text: "Just the nicest attorney I've met. Very straightforward and makes every effort to help his client. Would recommend 10/10.",
		name: 'Dinesh Arjun',
		stars: 5,
	},
	{
		text: "Adrian Pandev is a great lawyer. He is one of those rare lawyers that doe not only work for money. He really cares about people and helping them. If you are desperate and do not know what do just contact him you'll see for yourself",
		name: 'Shafagh K',
		stars: 5,
	},
	{
		text: 'I am extremely satisfied with my experience working with Pandev Law firm. Attorney Michael P. Hayes handled my case; he provided me with outstanding support and made the whole journey very smooth. Throughout the entire process, he handled each step with the highest level of professionalism. I really enjoyed working with Michael from the first meeting till the end of the process. I highly recommend him and the other attorneys at this firm!',
		name: 'George Darouni',
		stars: 5,
	},
];
