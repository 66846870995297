import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import get from 'lodash.get';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Hero from '../components/common/hero';
import heroImg from '../assets/img/private-clients/private-client-service.jpg';
import CtaDeck from '../components/common/cta-deck';
import SuccessStoriesDeck from '../components/common/Success-stories-deck';
import PilarNavigation from '../components/pilar/pilar-navigation';
import { PRIVATE_CLIENTS_TESTIMONIALS } from '../data/private-client-services/testimonials';
import AbTestingDeck from '../components/ab-testing/ab-testing-deck';
import CategorySlider from '../components/blog/category-slider';
import Reviewer from '../components/common/reviewer';
import Signature from '../components/common/signature';
import { prependStaticURL } from '../utils';

const headMeta = {
  title: 'Private Client Services | Pandev Law',
  description:
    'Our private client services are multidimensional, combining our in-depth knowledge in business, immigration, tax, and wealth preservation. Click to learn more.',
  keywords:
    'investor immigration,investor visa USA,entrepreneur visa USA,US green card by investment',
};

const schema = `[
  {
    "@context": "http://schema.org",
    "@type": "Product",
    "name": "Private Client Services | Pandev Law",
    "description": "Our private client services are multidimensional, combining our in-depth knowledge in business, immigration, tax, and wealth preservation. Click to learn more.",
    "url": "https://www.pandevlaw.com/private-client-services",
    "image": "https://pandevlaw.com/img/private-clients/private-client-service.jpg",
    "brand": "Pandev Law",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "5",
      "bestRating": "5",
      "worstRating": "0",
      "ratingCount": "246"
    },
    "sameAs": [
      "https://en-gb.facebook.com/pandevlaw/"
    ]
  },
  {
  "@context": "https://schema.org",
  "@type": "Article",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://www.pandevlaw.com/private-client-services/"
  },
  "headline": "Private Client Services",
  "description": ${JSON.stringify(headMeta.description)},
  "image": ${prependStaticURL(heroImg)},  
  "author": {
    "@type": "Person",
    "name": "Adrian Pandev",
    "url": "https://www.pandevlaw.com/about/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Pandev Law",
    "logo": {
      "@type": "ImageObject",
      "url": "https://www.pandevlaw.com/static/13437dd4aac9a31bf6176b69797f47be/61fd1/logo.webp"
    }
  },
  "datePublished": ""
}]`;

function PrivateClientServices({ location, data }) {
  const category = get(data, 'category', null);

  return (
    <Layout headMeta={headMeta} location={location} footerSchema={schema}>
      <Hero
        title="Private Client Services"
        img={heroImg}
        imgAlt="Private client services">
        <p className="text-lead mb-5 mb-md-6">
          Today’s business owners, investors, and high-net-worth individuals need a
          personal legal advisor who can devise comprehensive solutions regarding
          personal, business, tax, and mobility concerns. Our private client services are
          multidimensional, combining our in-depth knowledge in business, immigration,
          tax, and wealth preservation to create customized solutions for multinational
          individuals and families.
          <br />
          <br />
          Pandev Law’s private client services include inbound business, tax, and
          immigration planning for foreign families investing in the United States,
          relocating to the United States, or passing wealth to US resident beneficiaries.
          We also develop and implement effective outbound financial, business, and
          immigration strategies focused on investing, working, and living abroad. Our
          attorneys have extensive experience guiding clients through the US expatriation
          process.
          <br />
          <br />
          Our broad geographic reach enables us to provide borderless private client
          services. We have represented individuals and families with financial and
          personal interest on every continent of the world. Our clients include
          investors, business owners, families, private equity fund partners, private
          trust companies, and family-controlled companies, among others. We provide
          counsel in a wide range of private client areas, including:
        </p>
      </Hero>

      <AbTestingDeck
        position="top"
        title="Private Client Testimonials"
        testimonials={PRIVATE_CLIENTS_TESTIMONIALS}
      />

      <div className="section pt-5 pb-5 pt-md-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/private-clients/pre-immigration-planning.jpg"
                alt="Pre-Immigration Planning"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <Reviewer />
              <h2 className="text-headline mb-5 mb-md-6">Pre-Immigration Planning</h2>
              <p className="text-body mb-6">
                A comprehensive immigration strategy not only determines the right visa
                category for you and your family’s needs, but also encompasses
                pre-immigration tax planning and investment structuring. Each US visa
                category has different requirements, restrictions, and tax consequences
                for the holder. Our experienced business, estate planning, and immigration
                lawyers understand all aspects of the US immigration process and have
                helped multinational families achieve their goal of moving to the United
                States while also protecting their wealth. Our pre-immigration planning
                services include:
              </p>
              <div className="accordion" id="corporateLaw">
                <div className="mb-5">
                  <div id="corporateLawHeadingOne">
                    <h2
                      className="text-title text-primary accordion-trigger"
                      data-bs-toggle="collapse"
                      data-bs-target="#corporateLawCollapseOne"
                      aria-expanded="true"
                      aria-controls="corporateLawCollapseOne">
                      <strong>Choosing the Right Visa Category</strong>
                    </h2>
                  </div>
                  <div
                    id="corporateLawCollapseOne"
                    className="collapse show"
                    aria-labelledby="corporateLawHeadingOne"
                    data-parent="#corporateLaw">
                    <p className="text-body mt-4 mb-5">
                      There are over a hundred different US visa categories each with its
                      own requirements and processes. Your visa category determines how
                      long you can stay in the United States and what you are allowed to
                      do while you are in the United States. Your visa category and the
                      amount of days you spend within the United States in a year also
                      impacts your US tax resident status. Choosing the right visa
                      category for your and your family’s needs is absolutely critical.
                      Our immigration lawyers have a thorough understanding of all US visa
                      categories and can develop an immigration solution tailored to your
                      objectives.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="corporateLawHeadingTwo">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#corporateLawCollapseTwo"
                      aria-expanded="false"
                      aria-controls="corporateLawCollapseTwo">
                      <strong>Pre-Immigration Tax Planning</strong>
                    </h2>
                  </div>
                  <div
                    id="corporateLawCollapseTwo"
                    className="collapse"
                    aria-labelledby="corporateLawHeadingTwo"
                    data-parent="#corporateLaw">
                    <div className="mt-4">
                      <p>
                        The United States is one of a few countries that taxes its
                        residents on their worldwide income. Even nonresidents are taxed
                        on US sourced income, such as income, interest, and dividends from
                        US business operations, compensation for services rendered in the
                        United States, and rents and royalties from US real estate
                        investments. Given the significant tax consequences, a
                        pre-immigration tax plan is a critical component of a
                        comprehensive immigration strategy for those who wish to preserve
                        their wealth after moving to the United States.{' '}
                      </p>
                      <br />
                      <p>
                        There is a difference between US residence for income or estate
                        tax purposes and US residence for immigration purposes. Subject to
                        certain exceptions, a permanent resident (a green card holder),
                        even if a conditional permanent resident, is treated as an income
                        tax resident of the United States. Even a non-permanent resident
                        visa holder who spends the requisite number of days in the United
                        States may be treated as an income tax resident of the United
                        States. Moreover, a person physically present in and intending to
                        remain permanently in the United States will be treated as an
                        estate and gift tax resident. If a person dies as an estate and
                        gift tax resident, that person’s worldwide assets will be subject
                        to estate tax even if the person was not a US income tax resident.
                        An individual who is not an estate and gift tax resident could
                        still be subject to federal gift tax on transfers of US tangible
                        property and that person’s estate is taxable on all US property
                        valued over $60,000 at a maximum rate of 40%.{' '}
                      </p>
                      <br />
                      <p>
                        Our estate planning lawyers understand the significant tax
                        implications of relocating to the United States and can devise a
                        custom tailored pre-immigration tax plan for you and your family.
                        Our attorneys use various strategies to minimize US tax liability
                        for prospective immigrants, such as establishing trusts and
                        intermediate entities for qualified dividend distributions, using
                        related-party debt and foreign tax credits, and obtaining a
                        step-up in US tax basis of foreign assets through various
                        transactions.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="corporateLawHeadingThree">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#corporateLawCollapseThree"
                      aria-expanded="false"
                      aria-controls="corporateLawCollapseThree">
                      <strong>Pre-Immigration Investment Structuring</strong>
                    </h2>
                  </div>
                  <div
                    id="corporateLawCollapseThree"
                    className="collapse"
                    aria-labelledby="corporateLawHeadingThree"
                    data-parent="#corporateLaw">
                    <div className="mt-4">
                      <p>
                        Many US visa categories have specific investment and business
                        ownership requirements. For example, to qualify for an E-2
                        investor visa, the applicant must demonstrate that treaty country
                        nationals own at least 50% of the E-2 business and that the
                        invested funds come from a lawful source. The L-1 visa requires
                        that the US petitioning company demonstrate a qualifying
                        relationship, such as a parent, branch, subsidiary, or affiliate
                        relationship, with the foreign entity from which the beneficiary
                        is being transferred. While the H-1B visa permits the beneficiary
                        to be a shareholder of the petitioning company, it requires the
                        beneficiary to have an employer-employee relationship with the
                        petitioner which makes it difficult for the beneficiary to control
                        the company or serve on the board of directors.{' '}
                      </p>
                      <br />
                      <p>
                        Before a visa application can be approved, all the relevant
                        corporate relationships and investment transactions must be
                        correctly executed and documented. Our business and immigration
                        attorneys have a thorough understanding of the requirements of
                        each US visa category and have extensive experience with company
                        formations and cross-border transactions. We advise on the best
                        legal structure for your US company and investments, focusing on
                        providing optimal solutions from an immigration, tax, and business
                        perspective. Our lawyers take an objective approach, never
                        advising on what investment should be made, instead focusing on
                        how the investment transaction should be structured so that it
                        achieves your goals.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="offset-lg-1 col-lg-5 order-lg-2 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/private-clients/offshore-and-foreign-asset-compliance.jpg"
                alt="Offshore & Foreign Asset Compliance"
              />
            </div>
            <div className="col-lg-6 order-lg-1">
              <h2 className="text-headline mb-5 mb-md-6">
                Offshore &amp; Foreign Asset Compliance
              </h2>
              <p className="text-body mb-6">
                The United States has rigorous reporting requirements for US taxpayers who
                hold foreign bank accounts or foreign assets, or who may receive a gift or
                inheritance from abroad. If you fail to disclose your foreign assets to
                the IRS, you can be subject to severe penalties which in some cases can
                exceed the value of the unreported assets. Our wealth preservation lawyers
                thoroughly understand all five US voluntary disclosure programs. If you
                are a US tax resident considering a foreign investment, we will advise on
                structuring the deal so that it minimizes regulatory risks, maximizes tax
                benefits, and ensures compliance with disclosure requirements. If you are
                a foreign national wishing to relocate to or make an investment in the
                United States, we will develop a comprehensive strategy that complies with
                all reporting requirements thereby mitigating the risk of enforcement
                actions and compliance issues.
                <br />
                <br />
                Our wealth preservation lawyers have experience with all offshore and
                foreign asset compliance matters, including Report of Foreign Bank and
                Financial Accounts (FBAR), Foreign Account Tax Compliance (FATCA), inbound
                immigration rules and expatriation, Foreign Corrupt Practices Act (FCPA),
                and the Bank Secrecy Act. We understand and guide clients through the
                reporting requirements for gifts from individuals and corporations,
                distributions from foreign trusts, and ownership of foreign bank accounts,
                trusts, disregarded entities, and corporations. Not filing the relevant
                disclosures not only subjects you to significant penalties but also
                suspends the statute of limitations on your tax return until the
                applicable forms are filed with the IRS.
                <br />
                <br />
                As the government continues to broaden the list of circumstances that
                require disclosure filings, the burden grows on taxpayers to remain in
                compliance with ever stricter regulations. Whether you are seeking advice
                in preparing a comprehensive foreign asset compliance strategy or you have
                learned of your noncompliance and are seeking representation in an IRS
                enforcement action, our attorneys can counsel you on the proper manner in
                reporting all foreign assets. Our principal attorney served as a Trial
                Attorney at the US Department of Justice, which allows him to leverage
                relationships and knowledge gained as a government attorney to provide
                comprehensive compliance advice to clients.
              </p>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/private-clients/direct-investment.jpg"
                alt="Direct Investment"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <h2 className="text-headline mb-5 mb-md-6">Direct Investment</h2>
              <p className="text-body mb-5">
                Direct investments are a significant component of any high-net-worth
                individual or family’s investment strategy. Whether you are considering
                investing in an established business, an emerging growth company, real
                estate, or artwork, our attorneys can help you comply with regulatory
                requirements, improve your tax efficiency, and navigate cross-border
                issues as you deploy your capital globally.
                <br />
                <br />
                Clients value our multidisciplinary approach, combining our business and
                wealth preservation practices, and our customized, creative legal
                solutions in structuring investments. Our attorneys understand the
                complexities of direct investments and provide strategic legal guidance
                encompassing corporate, estate planning, and immigration considerations.
                Most of our clients have personal and financial interests in more than one
                country. We understand the cross-border investment process and have
                experience developing innovative legal solutions tailored to the specific
                needs of multinational individuals and families.
                <br />
                <br />
                We continue to represent and advise clients after the initial investment
                has concluded. We represent clients in regulatory compliance, corporate
                governance, and commercial transactions while they continue to hold their
                US assets. We also provide effective legal solutions that help clients
                identify, structure, and implement their exit strategy from their US
                investments.
              </p>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="offset-lg-1 col-lg-5 order-lg-2 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/private-clients/expatriation.jpg"
                alt="Expatriation"
              />
            </div>
            <div className="col-lg-6 order-lg-1">
              <h2 className="text-headline mb-5 mb-md-6">Expatriation</h2>
              <p className="text-body mb-6">
                Pandev Law is widely regarded as a leading law firm in the area of US
                expatriation. Our attorneys have a thorough understanding of the tax and
                immigration implications of expatriation. Regardless of the reasons why
                you wish to renounce your US citizenship or permanent residence, our
                lawyers will help you navigate and provide strategic advice throughout
                each step of your expatriation process.
                <br />
                <br />
                There are many reasons why you may wish to renounce your US citizenship.
                You may be an accidental US citizen who acquired US citizenship by birth
                but has no ties to the United States. You may be living abroad and find
                the extensive annual US tax reporting and tax filing requirements
                burdensome. You may be a foreign-based entrepreneur whose everyday banking
                activities are constantly obstructed due to your US citizenship. Or you
                may simply be sure that you will never wish to live in the United States
                again and do not see a reason to continue to hold US citizenship. No
                matter your personal reasons for seeking expatriation, our team of
                experienced expatriation lawyers can prepare your customized exit strategy
                from the United States.
              </p>
              <div className="accordion" id="internationalBusinessLaw">
                <div className="mb-5">
                  <div id="treatyTraderVisaZero">
                    <h2
                      className="text-title text-primary accordion-trigger"
                      data-bs-toggle="collapse"
                      data-bs-target="#treatyTraderVisaTargetZero"
                      aria-expanded="true"
                      aria-controls="treatyTraderVisaTargetZero">
                      <strong>Expatriation Representation</strong>
                    </h2>
                  </div>
                  <div
                    id="treatyTraderVisaTargetZero"
                    className="collapse show"
                    aria-labelledby="treatyTraderVisaZero"
                    data-parent="#internationalBusinessLaw">
                    <p className="text-body my-4">
                      Our attorneys have experience advising both US citizens in
                      relinquishing their citizenship and long-term US residents
                      abandoning their lawful permanent resident status. Our attorneys
                      rely on our firm’s extensive immigration, tax, and wealth
                      preservation experience to create multidisciplinary expatriation
                      strategies for our clients. We aim to minimize your tax liability
                      while ensuring completion of all legal formalities of the
                      expatriation process.
                      <br />
                      <br />
                      Our attorneys analyze whether you are a covered expatriate subject
                      to the US exit tax. If it is impossible to avoid a covered
                      expatriate classification, we develop customized, creative solutions
                      to avoid or minimize your US exit tax liability. Our expatriation
                      lawyers represent you before the US Department of State (DOS), US
                      Citizenship and Immigration Services (USCIS), and the Internal
                      Revenue Service (IRS), filing all necessary legal forms and
                      documents and preparing you for any in-person interviews. Our
                      attorneys will work with all your other professional advisors,
                      including tax advisors, to ensure your final US tax returns,
                      including Form 8854, are successfully prepared. Our custom tailored
                      expatriation strategy will always include your unique
                      post-expatriation plan, especially if you will continue to have US
                      assets, investments, business interests, or if you plan to visit the
                      United States temporarily after expatriation.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="treatyTraderVisaOne">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#treatyTraderVisaTargetOne"
                      aria-expanded="false"
                      aria-controls="treatyTraderVisaTargetOne">
                      <strong>Second Citizenship By Investment</strong>
                    </h2>
                  </div>
                  <div
                    id="treatyTraderVisaTargetOne"
                    className="collapse"
                    aria-labelledby="treatyTraderVisaOne"
                    data-parent="#internationalBusinessLaw">
                    <p className="text-body my-4">
                      Before your expatriation process can be finalized, you must
                      establish citizenship in a jurisdiction other than the United
                      States. If you are not already a dual citizen, you will need to
                      obtain a second citizenship prior to expatriation. Our attorneys are
                      highly experienced in the citizenship by investment process. We keep
                      up to date with the various citizenship by investment programs
                      available worldwide. We advise clients on the best citizenship
                      program for their particular needs and help guide them through the
                      citizenship application process.
                      <br />
                      <br />
                      When deciding the best citizenship by investment solution for our
                      clients, we perform a comprehensive multi factor analysis. We
                      consider the required investment amount and the type of investments
                      that qualify an individual and their family members for the program.
                      We carefully review the income, capital gains, estate, inheritance,
                      gift, transfer, and withholding tax rules of the jurisdiction to
                      ensure that the citizenship will benefit our client’s overall wealth
                      preservation plan. Other important factors we consider include the
                      length of the application process, visa free travel and mobility,
                      the strength of healthcare and education systems, and stability of
                      banks and civil institutions in the jurisdiction.
                      <br />
                      <br />
                      In Europe, popular citizenship by investment jurisdictions include
                      Malta and Montenegro. Caribbean countries, such as Antigua, St.
                      Lucia, and St. Kitts and Nevis also provide citizenship by
                      investment programs. Grenada is an attractive citizenship by
                      investment option for US expatriates who continue to have US
                      business interests. Grenadian citizens are eligible for a US E-2
                      visa which allows non-US citizens to temporarily reside in the
                      United States to direct and develop a US enterprise. Our attorneys
                      possess the depth and breadth of experience to develop the most
                      effective second citizenship strategy for your and your family’s
                      needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/private-clients/closely-held-companies.jpg"
                alt="Closely Held Companies"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <h2 className="text-headline mb-5 mb-md-6">Closely Held Companies</h2>
              <p className="text-body mb-6">
                Pandev Law’s attorneys have extensive experience counseling closely held
                companies, private companies, and family businesses. We provide a
                wide-range of services from representation in formation of companies, to
                shareholder agreements, commercial contracts, acquisitions, financing,
                succession planning, and intellectual property protection and licensing.
                <br />
                <br />
                Choosing the correct legal structure for a new venture is critical. This
                is especially true for foreign entrepreneurs who face challenging tax and
                immigration compliance requirements. Our attorneys bring together our
                business law, intellectual property, immigration, and estate planning
                experience to develop the best structure and rules for your new business.
                We have experience forming limited liability companies, C and S
                corporations, and partnerships in both the entrepreneur’s home state or
                business-friendly jurisdictions, such as Delaware and Wyoming. We use
                agreements and extensive planning to implement organizational structures
                which facilitate growth and reduce the risk of costly shareholder
                conflicts. Our lawyers advise on the right management structure,
                confidentiality, repurchase rights, competition and buy-sell restrictions,
                and assignment of inventions.
                <br />
                <br />
                Raising capital is essential for new ventures to continue to grow. Our
                attorneys draw on their multidisciplinary experience in business and
                startup law to advise on debt and equity financing. We have a deep
                understanding of the pre-seed, seed, and series financing process. We
                routinely represent companies in convertible equity, convertible debt,
                sale of common stock, regulation crowdfunding, and seed round preferred
                stock transactions.
                <br />
                <br />
                Our closely held company services included drafting specifically tailored
                contracts in sectors ranging from technology to fashion, hospitality,
                finance, consumer goods, and healthcare, among others. We have a long
                track record of successfully representing clients in negotiations and
                providing strategic advice throughout the negotiation process, always
                focusing on our clients’ objectives and needs.
                <br />
                <br />
                Representation in mergers and acquisitions is a core component of our
                closely held company practice. Our attorneys have deep experience
                representing foreign investors in expanding their US business holdings. We
                devise investment strategies and structure acquisitions to protect our
                clients’ interests, maximizing liability protection and tax efficiency.
                Our lawyers understand the importance of a comprehensive exit strategy
                whether through selling the business or passing it onto the next
                generation. We regularly represent sellers of businesses, advising on the
                best transaction structure and negotiating critical terms, such as
                sellers’ ongoing duties, liability, non-competition, and confidentiality.
              </p>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="offset-lg-1 col-lg-5 order-lg-2 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/private-clients/business-succession-planning.jpg"
                alt="Business Succession Planning"
              />
            </div>
            <div className="col-lg-6 order-lg-1">
              <h2 className="text-headline mb-5 mb-md-6">Business Succession Planning</h2>
              <p className="text-body mb-6">
                Business succession planning is essential for closely held and
                family-owned businesses. As a business matures, leadership has to consider
                shifting ownership and executive responsibilities to the next generation.
                Given the personal relationships, business interests, and legal
                considerations involved, such transitions can be quite challenging.
                <br />
                <br />
                Our attorneys devise real-world legal solutions focused on preserving
                company value and viability across generations. We provide strategic legal
                counsel on balancing individual, family, and business interests with the
                goal of developing a business succession plan that is effective
                irrespective of whether the transition occurs as part of a planned
                retirement or an unexpected death or disability. We use various business
                succession strategies, including establishing private annuities, trusts,
                and lifetime gifting arrangements as well as negotiating stock transfers
                and buy-sell agreements to improve tax efficiency and to avoid family
                disputes.
                <br />
                <br />
                We strongly believe that the best business succession plan begins the
                moment a company is formed and must continue to adapt as the business
                matures. As part of our company formation services, we counsel clients on
                the business, tax, and transfer of ownership advantages of all business
                structures, including limited liability companies, S corporations, and
                family limited partnerships. A sound business succession plan implemented
                at the launch of a new enterprise ensures transparency and predictability
                which minimizes the risk of disputes and maximizes the company’s growth
                potential across generations.
              </p>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/private-clients/wealth-protection.jpg"
                alt="Wealth Protection"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <h2 className="text-headline mb-5 mb-md-6">Wealth Protection</h2>
              <p className="text-body mb-6">
                We understand that you would like your wealth to go to your family, loved
                ones, or charities of your choosing. Our wealth protection practice
                develops creative wealth preservation solutions for high-net-worth
                multinational families, individuals, trusts, and closely held businesses.
                We serve as your personal general counsel, crafting comprehensive
                solutions to all of your worldwide transactional needs, whether it’s
                purchasing a vacation home in Florida, selling an investment property in
                New York, or investing in a Silicon Valley startup.
                <br />
                <br />
                Our global wealth protection practice spans every continent of the world.
                Our attorneys have a deep understanding of the international dimensions of
                wealth transfer and corporate succession planning as well as cross-border
                estate planning. For your hard earned assets to be passed on to your
                desired beneficiaries requires careful planning and navigation of relevant
                income, gift, and estate tax rules. Our attorneys devise customized
                estate, asset-management, and business succession plans for multinational
                businesses, individuals, and families. Our wealth protection lawyers use
                various legal vehicles to deliver successful asset protection solutions to
                our clients, including dynasty trusts, qualified personal residence
                trusts, intrafamily sales and loans, buy-sell agreements, second
                citizenships and residences, wealth replacement life insurance trusts, and
                non-voting common stock recapitalization of closely held corporations,
                among others.
                <br />
                <br />
                We understand that personal situations, finances, and goals are ever
                evolving. Your wealth protection plan must be continuously adapted to the
                changing circumstances of your life and the changing laws and regulations
                in the jurisdictions to which you are exposed. Our wealth protection
                lawyers work with you over your lifetime, collaborating with all your
                other professional advisors, to preserve your and your family’s wealth.
              </p>
            </div>
          </div>
          <Signature />
        </div>
      </div>
      {get(category, 'posts.nodes.length', 0) > 0 && (
        <>
          <hr className="my-8" />
          <CategorySlider index={2} category={category} />
        </>
      )}
      <AbTestingDeck
        title="Private Client Testimonials"
        testimonials={PRIVATE_CLIENTS_TESTIMONIALS}
      />
      <PilarNavigation />
      <CtaDeck />
      <SuccessStoriesDeck />
    </Layout>
  );
}

export default PrivateClientServices;

export const query = graphql`
  query PrivateClientServicesQuery {
    category: wpCategory(slug: { eq: "private-client" }) {
      ...BlogLandingHeroPost
    }
  }
`;
